import React, { useState } from "react"
import cx from "classnames"

import Button from "components/Button"
import Container from "components/Container"
import Page from "components/Page"
import linkStyles from "components/Link/Link.module.scss"
import { getParams } from "utils/form"

import styles from "./contact-us.module.scss"

const ContactUs = () => {
  const [status, setStatus] = useState("idle") // idle, sending, sent
  const [error, setError] = useState("")

  const handleSubmit = e => {
    e.preventDefault()

    setStatus("sending")
    setError("")

    const { email, name, company, content } = getParams(e.target)

    fetch(`${process.env.GATSBY_API_URL}/contact`, {
      method: "POST",
      body: JSON.stringify({ email, name, company, content }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async res => {
        if (!res.ok) {
          const { message } = await res.json()
          throw Error(message)
        }

        setStatus("sent")
      })
      .catch(err => {
        setError(err.message || "Something went wrong. Try again later.")
        setStatus("idle")
      })
  }

  return (
    <Page showFooterCTA={false}>
      <Container containerClassName={styles.container} className={styles.wrap}>
        <div>
          <h1>Let's transform the way you work</h1>

          <p>
            Drop us a line, explaing what you struggle with and we will get in
            touch as soon as possible.
          </p>

          <p>
            Or send us an email at:{" "}
            <a
              className={cx(linkStyles.link, linkStyles.underline)}
              href="mailto:hi@theremot.com"
            >
              <b>hi@theremot.com</b>
            </a>
          </p>
        </div>

        <div>
          {error && <p className={styles.error}>{error}</p>}

          {status !== "sent" ? (
            <form onSubmit={handleSubmit} className={styles.form}>
              <input required type="text" name="name" placeholder="Full name" />
              <input
                required
                type="email"
                name="email"
                placeholder="Email Address"
              />
              <input
                required
                type="text"
                name="company"
                placeholder="Company Name"
              />

              <textarea
                required
                name="content"
                placeholder="How can we help you?"
              />
              <Button.CTA
                disabled={status === "sending"}
                loading={status === "sending"}
                type="submit"
              >
                Send
              </Button.CTA>
            </form>
          ) : (
            <p className={styles.success}>
              Thanks for reaching out. We will get in touch soon.
            </p>
          )}
        </div>
      </Container>
    </Page>
  )
}

export default ContactUs
